import React, {useContext, useRef, useState} from "react";
import {useDrag, useDrop} from "react-dnd";
import {Button, Dropdown, IconButton, Loader, Modal, Popover, Whisper} from "rsuite";
import StatsLabel from "../../../statistics/time-aggregation/stats-label";
import PriceDisplay from "./price-display";
import CalculationContext from "../context";
import {useDispatch, useSelector} from "react-redux";
import {
    moveEntry,
    removeEntry,
    selectEntryById,
    setEntryField,
    toggleOccupancyChoice,
    useEntryField
} from "../store/entries-slice";
import {useField} from "../store/fields-slice";
import {useChoiceChildPrices, useChoiceColumns, useChoiceIds} from "../store/choices-slice";
import {confirm} from "../../../dialog/confirm";
import Action from "./action";
import DebouncedInput from "./debounced-input";
import {useCalculationDetails} from "../store/calculation-details";
import {updateManualReload, useDisplayColumns, useHideOccupancyChoices} from "../store/state-slice";
import cs from "classnames";

const FIELD_PROPERTIES = Object.freeze({
    day: {min: 1},
    duration: {step: 1},
    count: {min: 1, step: 1},
    exchangeRate: {min: .01, step: .01}
})

export default function CalculationTableEntry({id, group}) {
    const dispatch = useDispatch()
    const {i18n} = useContext(CalculationContext)
    const displayColumns = useDisplayColumns()
    const choices = useChoiceColumns()
    const entry = useSelector(state => selectEntryById(state, id))
    const hideOccupancyChoices = useHideOccupancyChoices()
    const tr = useRef(null)

    const [{isDragging}, drag, dragPreview] = useDrag(() => ({
        type: "calculation-entry",
        collect: monitor => ({
            isDragging: !!monitor.isDragging()
        }),
        item: {
            id
        },
        options: {
            dropEffect: "move"
        }
    }))

    const [{isOver, canDrop}, drop] = useDrop(() => ({
        accept: "calculation-entry",
        drop: target => dispatch(moveEntry(target.id, id)),
        canDrop: target => target.id !== id,
        collect: monitor => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop()
        })
    }))

    dragPreview(drop(tr))

    return (
        <tr ref={tr} style={{opacity: isDragging ? .5 : 1, borderTop: (isOver && canDrop) ? "3px solid red" : null}}>
            <td className="selector">
                <span ref={drag} aria-hidden="true" className="fa fa-bars" style={{cursor: "ns-resize"}}/>
            </td>
            <td className="service">
                <div className="pull-right">
                    {entry.edit && (
                        <EditEntry
                            service={entry.service}
                            edit={entry.edit}
                            i18n={i18n}
                            group={group}
                            dispatch={dispatch}
                        />
                    )}

                    { entry.priceSets && entry.priceSets.length > 0 ?
                        <EditPricePageEntryEntry
                            priceSets={entry.priceSets}
                            priceSetUrl={entry.priceSetUrl}
                            i18n={i18n}
                            dispatch={dispatch}
                        />
                    : null}

                    <RemoveEntry
                        id={entry.identifier}
                        objectLabel={entry.service.objectLabel}
                        i18n={i18n}
                        dispatch={dispatch}
                    />
                </div>

                {entry.service.objectLabel ? <StatsLabel label={entry.service}/> : <Loader/>}

                {entry.provider && (
                    <small><br/><StatsLabel label={entry.provider}/></small>
                )}

                {entry.additionalData && entry.additionalData.map(data => {
                    return <><br/><small>{data}</small></>;
                })}
            </td>

            {displayColumns.map((field, idx) => (
                <td key={field} className={"text-right" + (idx === (displayColumns.length - 1) ? " border-right" : "")}>
                    {"currency" === field ? (
                        entry.currency
                    ) : (
                        <>
                            <TableEntryField id={id} name={field} {...FIELD_PROPERTIES[field]}/>
                            {"exchangeRate" === field && "number" === typeof entry.exchangeRateDefault && (
                                <small><br/>{entry.exchangeRateDefault}</small>
                            )}
                        </>
                    )}
                </td>
            ))}

            {hideOccupancyChoices ? null : entry.additionalService ? (
                <td colSpan={choices} className="border-right"/>
            ) : (
                <TableEntryChoices entryId={id}/>
            )}

            <PriceDetails id={id}/>
        </tr>
    )
};

function TableEntryField({id, name, ...props}) {
    const dispatch = useDispatch()
    const value = useEntryField(id, name)

    return (
        <DebouncedInput
            type="number"
            value={value}
            onChange={v => dispatch(setEntryField(id, name, v))}
            {...props}
        />
    )
}

function TableEntryChoices({entryId}) {
    const dispatch = useDispatch()
    const choices = useChoiceIds()
    const value = useEntryField(entryId, "occupancyChoices")
    const childValue = useEntryField(entryId, "childChoices")
    const childPrices = useChoiceChildPrices()
    const items = []

    for (const id of choices) {
        items.push({checked: value.includes(id), id})

        for (const [childId] of childPrices?.[id] ?? []) {
            items.push({checked: childValue.includes(childId), childId})
        }
    }

    return items.map(({checked, id, childId}, idx) => (
        (
            <td key={idx} className={cs("occupancy-small", {"border-right": idx + 1 === items.length})}>
                <input type="checkbox" checked={checked}
                       onChange={() => dispatch(toggleOccupancyChoice(entryId, id, childId, !checked))}/>
            </td>
        )
    ))
}

const PriceDetails = ({id}) => {
    const {updating, columns, reduction, currency: entryCurrency} = useSelector(state => selectEntryById(state, id))
    const marginParticipants = useField("marginParticipants")
    const currency = useField("currency")

    return columns.map(({key, border, from, to, ...props}) => (
        <td key={key} className={cs("price-cell", "text-right", "pax", {
            "border-right": border,
            "margin-pax": from <= marginParticipants && marginParticipants <= to
        })}>
            <PriceDetailDisplay updating={updating} currency={currency} entryCurrency={entryCurrency}
                                reduction={reduction} {...props}/>
        </td>
    ))
}

const PriceDetailDisplay = (
    {
        currency, entryCurrency, updating,
        purchase, purchaseOrigin,
        retail, retailOrigin, calculatedDuration, reduction
    }
) => {
    const {currencyConverter} = useContext(CalculationContext)
    const table = []

    if (reduction) {
        purchase = purchase * -1;
        retail = retail * -1;
    }

    if ("number" === typeof retail || "number" === typeof retailOrigin || "number" === typeof purchaseOrigin) {
        if ("number" === typeof purchase) {
            table.push(["EK", currencyConverter.toView({amount: purchase, currency})])
        } else if ("number" === typeof purchaseOrigin) {
            table.push(["EK", `kein Währungskurs für ${entryCurrency} ermittelbar`])
        } else {
            table.push(["EK", "kein EK"])
        }

        if ("number" === typeof purchaseOrigin) {
            table.push(["EK (Währung)", currencyConverter.toView({
                amount: purchaseOrigin,
                currency: entryCurrency
            })])
        }

        if ("number" === typeof retail && retail) {
            table.push(["VK", currencyConverter.toView({amount: retail, currency})])
        }

        if ("number" === typeof retailOrigin && retailOrigin) {
            table.push(["VK (Währung)", currencyConverter.toView({
                amount: retailOrigin,
                currency: entryCurrency
            })])
        }
    }

    if (calculatedDuration) {
        table.push(["Dauer", calculatedDuration])
    }

    const speaker = !table.length ? null : (
        <Popover>
            <table className="table table-super-condensed calculation-overlay">
                <tbody>
                {table.map(([th, td], idx) => (
                    <tr key={idx}>
                        <th>{th}:</th>
                        <td>{td}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </Popover>
    );

    return (
        <Whisper trigger={speaker ? "hover" : "none"} placement="auto" speaker={speaker ?? <div/>}>
            <div>
                <PriceDisplay money={{amount: purchase, currency}} updating={updating} number
                              currencyConverter={currencyConverter}/>
                {"number" === typeof purchaseOrigin && (
                    <small>
                        <br/>
                        {"(" + currencyConverter.toView({amount: purchaseOrigin, currency: entryCurrency}) + ")"}
                    </small>
                )}
            </div>
        </Whisper>
    )
}

function EditPricePageEntryEntry({priceSets, priceSetUrl}) {
    const {viewActionHandler} = useContext(CalculationContext)
    const {refetch} = useCalculationDetails()
    const dispatch = useDispatch()

    if (priceSets.length === 1) {
        const actionContext = {
            id: priceSets[0].id,
            modelId: 'price/price-page',
            moduleId: 'price',
            viewId: 'manage-price-page',
            modal: true
        }

        return (

            <Action size="xs" appearance="link"
                    handler={viewActionHandler}
                    context={actionContext}
                    onResult={() => {refetch(); dispatch(updateManualReload(true))}}>
                <i className="fa fa-pencil"/>
            </Action>
        )
    }

    //Todo check if possible to edit directly
    return <>
        <a href={priceSetUrl} className="rs-btn rs-btn-link rs-btn-xs" target={'_blank'}><i
            className="fa fa-pencil"/></a>
    </>

}

function EditEntry({service: {id, modelId}, edit, group}) {
    const {viewActionHandler} = useContext(CalculationContext)
    const calculationId = useField("id")
    const {refetch} = useCalculationDetails()
    const actionContext = !edit ? undefined : {
        id, modelId,
        moduleId: edit.split('/')[0],
        viewId: edit.split('/')[1],
        modal: true,
        contextObjectRef: {
            id: calculationId,
            modelId: "tourism-journey-calculation/calculation",
            group
        }
    }

    return (
        <Action size="xs" appearance="link"
                handler={viewActionHandler}
                context={actionContext}
                onResult={() => refetch()}>
            <i className="fa fa-pencil"/>
        </Action>
    )
}

function RemoveEntry({id, objectLabel, i18n, dispatch}) {
    const [loading, setLoading] = useState(false)

    const onClick = () => {
        setLoading(true)
        confirm(objectLabel, i18n.tr('tourism-journey-calculation.confirmDelete', {sprintf: {title: objectLabel}}))
            .then(() => {
                dispatch(removeEntry(id))
               dispatch(updateManualReload(true))
            }, () => {
                setLoading(false)
            })
    }

    return (
        <Button size="xs" appearance="link" loading={loading} onClick={onClick}>
            <i className="fa fa-trash"/>
        </Button>
    )
}
