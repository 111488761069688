import {bindable, customElement, inject} from "aurelia-framework";
import {Client} from "../../api/client";
import {EventAggregator} from "aurelia-event-aggregator";
import {DialogService} from "aurelia-dialog";
import {FlashService} from "../../flash/flash-service";
import {I18N} from "aurelia-i18n";
import {BindingSignaler} from "aurelia-templating-resources";
import {CurrencyValueConverter} from "../../currency/currency-value-converter";
import "../../array/array-move";
import * as _ from "lodash";
import {debounce} from "../../utilities/debounce";

import "./calculation.less";
import {Confirm} from "../../dialog/confirm";
import moment from "moment-timezone";
import {DialogView} from "../../view/dialog-view";
import {WorkflowService} from "../../workflow/workflow-service";

@inject(
    Client,
    EventAggregator,
    DialogService,
    FlashService,
    I18N,
    BindingSignaler,
    CurrencyValueConverter,
    WorkflowService
)
@customElement("tourism-hotel-calculation")
export class Calculation {
    @bindable itinerary;
    @bindable calculation;
    @bindable theadSortable;

    calculationResult;

    seasons = ["A", "B", "C", "D", "E", "F", "G", "H"];
    seasonsConfig;

    client;
    ea;
    dialog;
    flash;
    i18n;
    signaler;

    loading = false;
    detailsLoading = false;
    rowGeneratorActive = false;
    rowGeneratorEnabled = false;
    runGenerate = false;

    usedOccupancyTypes = null;
    endpoint = null;

    configuration = {};

    arrivalDays = [
        {value: 1, label: "base.choiceSet.dayOfWeek.mon"},
        {value: 2, label: "base.choiceSet.dayOfWeek.tue"},
        {value: 3, label: "base.choiceSet.dayOfWeek.wed"},
        {value: 4, label: "base.choiceSet.dayOfWeek.thu"},
        {value: 5, label: "base.choiceSet.dayOfWeek.fri"},
        {value: 6, label: "base.choiceSet.dayOfWeek.sat"},
        {value: 7, label: "base.choiceSet.dayOfWeek.sun"},
    ];

    constructor(
        client,
        ea,
        dialog,
        flash,
        i18n,
        signaler,
        currencyValueConverter,
        workflowService
    ) {
        this.client = client;
        this.ea = ea;
        this.dialog = dialog;
        this.flash = flash;
        this.i18n = i18n;
        this.signaler = signaler;
        this.currencyValueConverter = currencyValueConverter;
        this.workflowService = workflowService;
        this.groups = new Map();
        this.workflowService = workflowService;

        let seasonChoices = [];

        for (let i = 0; i < this.seasons.length; i++) {
            seasonChoices.push({
                value: i,
                label: this.seasons[i],
            });
        }

        this.seasonsConfig = {
            choices: seasonChoices,
        };

        this.getDetailsDebounced = debounce(this.getDetails, 250);
    }

    _getControlUID(calculation) {
        return "tourism-hotel-calculation";
    }

    async bind() {
        await this.getHotelCalculationConfiguration();

        this.calculation = null;

        let calculations = await this.client.get(
            "tourism-hotel-calculation/calculation?" +
                "embeds[]=itinerary&conditions=" +
                JSON.stringify({ itinerary: { $eq: this.itinerary.id } })
        );

        calculations = (calculations.items || [])
            .map((calculation) => ({
                id: calculation.id,
                fromDate: calculation.fromDate,
                toDate: calculation.toDate,
                label: calculation.label,
                active: !!calculation.active,
            }))
            .sort((a, b) => {
                return a.fromDate === b.fromDate
                    ? new Date(a.toDate) - new Date(b.toDate)
                    : new Date(a.fromDate) - new Date(b.fromDate);
            });

        const now = moment()
        const checkCurrent = ({fromDate, toDate}) => (!fromDate || moment(fromDate).isBefore(now)) &&
            (!toDate || moment(toDate).isAfter(now))

        this.activeCalculations = calculations.filter(c => c.active && checkCurrent(c));
        this.inactiveCalculations = calculations.filter(c => !c.active || !checkCurrent(c));

        const id = sessionStorage.getItem(
            "tourism_hotel_calculation/calculation"
        );

        if (id) {
            let found = false;

            for (let calc of calculations) {
                if (id === calc.id) {
                    this.selectCalculation(id);
                    found = true;
                    break;
                }
            }

            if (!found) {
                this.selectOverview();
            }
        } else {
        }

        this.loading = false;
    }

    getArrivalDays() {
        return this.arrivalDays.map((day) => {
            return {
                value: day.value,
                label: this.i18n.tr(day.label)
            }
        })
    }

    selectOverview() {
        if (!this._checkUnsavedChanges()) {
            return;
        }

        this.setSavedState();
        this.overview = true;
        this.earlyBooking = false;
        this.calculation = null;
        sessionStorage.setItem("tourism_hotel_calculation/calculation", null);
    }

    selectEarlyBooking() {
        if (!this._checkUnsavedChanges()) {
            return;
        }

        this.setSavedState();
        this.earlyBooking = true;
        this.overview = false;
        this.calculation = null;
        sessionStorage.setItem("tourism_hotel_calculation/calculation", null);
    }

    _checkUnsavedChanges() {
        if (!this.unsaved) {
            return true;
        }

        return confirm(this.i18n.tr("dialog.ask_really_close"));
    }

    async selectCalculation(id) {
        if (!this._checkUnsavedChanges()) {
            return;
        }

        this.setSavedState();
        this.calculationLoading = true;
        sessionStorage.setItem("tourism_hotel_calculation/calculation", id);

        const calculation = await this.client.get(
            "tourism-hotel-calculation/calculation/" + id
        );

        //Bug with checkboxes input and config already loaded
        if (!calculation.arrivalWeekDays) {
            calculation.arrivalWeekDays = [];
        }

        if (!calculation.deviatingArrivalWeekDays) {
            calculation.deviatingArrivalWeekDays = [];
        }

        this.calculation = calculation;
        this.calculationResult = null;
        this.overview = false;
        this.earlyBooking = false;

        this._updatedUsedOccupancies();
        this.getDetailsDebounced();

        for (let i = this.calculation.hotelPurchasePrices.length; i < 8; i++) {
            this.calculation.hotelPurchasePrices.push({});
        }

        for (let i = this.calculation.retailPrices.length; i < 8; i++) {
            this.calculation.retailPrices.push({});
        }

        for (let i = this.calculation.rows.length; i < 5; i++) {
            this.calculation.rows.push({});
        }

        this.calculation.rowGeneratorRetailPrice = this.calculation.rowGenerator
            .filter(function (elem) {
                return elem.priceType == "retailPrice";
            })
            .sort((a, b) => {
                return a.fromDate === b.fromDate
                    ? new Date(a.toDate) - new Date(b.toDate)
                    : new Date(a.fromDate) - new Date(b.fromDate);
            });

        this.calculation.rowGeneratorPurchasePrice = this.calculation.rowGenerator
            .filter(function (elem) {
                return elem.priceType == "purchasePrice";
            })
            .sort((a, b) => {
                return a.fromDate === b.fromDate
                    ? new Date(a.toDate) - new Date(b.toDate)
                    : new Date(a.fromDate) - new Date(b.fromDate);
            });

        this.calculation.additionalRooms.rows = this.calculation?.additionalRooms?.map(
            (oneRow) => {
                return {
                    ...oneRow,
                    rows: oneRow.rows.sort((a, b) => {
                        return a.fromDate === b.fromDate
                            ? new Date(a.toDate) - new Date(b.toDate)
                            : new Date(a.fromDate) - new Date(b.fromDate);
                    }),
                };
            }
        );

        this.calculation.additionalFares = this.calculation?.additionalFares?.map(
            (oneRow) => {
                return {
                    ...oneRow,
                    rows: oneRow.rows.sort((a, b) => {
                        return a.fromDate === b.fromDate
                            ? new Date(a.toDate) - new Date(b.toDate)
                            : new Date(a.fromDate) - new Date(b.fromDate);
                    }),
                };
            }
        );

        this.calculation.childReductions = this.calculation?.childReductions?.map(
            (oneRow) => {
                return {
                    ...oneRow,
                    rows: oneRow.rows.sort((a, b) => {
                        return a.fromDate === b.fromDate
                            ? new Date(a.toDate) - new Date(b.toDate)
                            : new Date(a.fromDate) - new Date(b.fromDate);
                    }),
                };
            }
        );

        this.checkRowGenerator();

        this.calculationLoading = false;
        console.log(this.calculation, "calculations");
    }

    addDeviatingArrivalWeekDays() {
        this.calculation.deviatingArrivalWeekDays.push({});
        this.changed();
    }

    removeDeviatingArrivalWeekDays(index) {
        this.calculation.deviatingArrivalWeekDays.splice(index, 1);
        this.changed();
    }

    addRow() {
        this.calculation.rows.push({});
        this.changed();
    }

    removeRow(index) {
        this.calculation.rows.splice(index, 1);
        this.changed();
    }

    addRetailPriceSeason() {
        this.calculation.rowGeneratorRetailPrice.push({});
        this.checkRowGenerator();
        this.changed();
    }

    removeRetailPriceSeason(index) {
        this.calculation.rowGeneratorRetailPrice.splice(index, 1);
        this.checkRowGenerator();
        this.changed();
    }

    addPurchasePriceSeason() {
        this.calculation.rowGeneratorPurchasePrice.push({});
        this.checkRowGenerator();
        this.changed();
    }

    removePurchasePriceSeason(index) {
        this.calculation.rowGeneratorPurchasePrice.splice(index, 1);
        this.checkRowGenerator();
        this.changed();
    }

    checkRowGenerator() {
        this.rowGeneratorActive =
            (this.calculation.rowGeneratorPurchasePrice.length > 0 ||
                this.calculation.rowGeneratorRetailPrice.length > 0) &&
            this.calculation.rowGeneratorEnabled;
    }

    //Additional rooms

    async askUseRows() {
        let response = await this.dialog
            .open({
                viewModel: Confirm,
                model: {
                    message:
                        "Sollen die Anreisezeiträume aus der Basis-Kalkulation übernommen werden?",
                    okMessage: "Ja",
                    cancelMessage: "Nein",
                },
            })
            .whenClosed();

        return !response.wasCancelled;
    }

    async getDateRows(always) {
        let askUseRows = always ? true : await this.askUseRows();

        let rows = [{}, {}, {}];

        if (askUseRows) {
            rows = [];

            for (let row of this.calculation.rows) {
                if (!row.fromDate || !row.toDate) {
                    continue;
                }

                rows.push({ fromDate: row.fromDate, toDate: row.toDate });
            }
        }

        return rows.sort((a, b) => {
            return a.fromDate === b.fromDate
                ? new Date(a.toDate) - new Date(b.toDate)
                : new Date(a.fromDate) - new Date(b.fromDate);
        });
    }

    async addAdditionalRoom() {
        this.calculation.additionalRooms.push({
            rows: await this.getDateRows(),
        });
        this.changed();
    }

    addAdditionalRoomRow(index) {
        this.calculation.additionalRooms[index].rows.push({});
        this.changed();
    }

    removeAdditionalRoomRow(parentIndex, index) {
        this.calculation.additionalRooms[parentIndex].rows.splice(index, 1);
        this.changed();
    }

    removeRoom(index) {
        this.calculation.additionalRooms.splice(index, 1);
        this.changed();
    }

    //Additional fares

    async addAdditionalFare() {
        this.calculation.additionalFares.push({
            rows: await this.getDateRows(),
        });
        this.changed();
    }

    addAdditionalFareRow(index) {
        this.calculation.additionalFares[index].rows.push({});
        this.changed();
    }

    removeAdditionalFareRow(parentIndex, index) {
        this.calculation.additionalFares[parentIndex].rows.splice(index, 1);
        this.changed();
    }

    removeFare(index) {
        this.calculation.additionalFares.splice(index, 1);
        this.changed();
    }

    //Child reduction

    async addChildReduction() {
        this.calculation.childReductions.push({
            rows: await this.getDateRows(true),
        });
        this.changed();
    }

    addChildReductionRow(index) {
        this.calculation.childReductions[index].rows.push({});
        this.changed();
    }

    removeChildReductionRow(parentIndex, index) {
        this.calculation.childReductions[parentIndex].rows.splice(index, 1);
        this.changed();
    }

    removeChildReduction(index) {
        this.calculation.childReductions.splice(index, 1);
        this.changed();
    }

    //Surcharges

    addSurcharge() {
        this.calculation.surcharges.push({arrivalType: "arrival"});
        this.changed();
    }

    removeSurcharge(index) {
        this.calculation.surcharges.splice(index, 1);
        this.changed();
    }

    finishContext(dialogController) {
        if (dialogController && this.currentData) {
            dialogController.ok(this.currentData);
        }
        this.currentData = null;
    }

    async addAdditionalServiceGroup(index, service) {

        let changed = false;
        await this.dialog
            .open({
                viewModel: DialogView,
                model: {
                    title: "Wunschleistung hinzufügen",
                    data: this.calculation,
                    content: `
                    <sio-form
                        config="tourism-journey/additional-service-add"
                        submit.bind="params.submitHandler"
                        data.bind="params.data"
                        sio-post-submit.trigger="params.finishContextHandler(dialogController)" 
                    ></sio-form>
                `,
                    finishContextHandler: this.finishContext.bind(this),
                    submitHandler: (value) => {

                        return this.workflowService.trigger(
                            'tourism-hotel-calculation/additional-service-create-hotel-calculation',
                            null,
                            value.object,
                        ).then(data => {
                            //Workflow handles successes as promise fullfilment, form errors are displayed
                            if (data.errors && data.code === 400) {
                                throw {data: data};
                            }

                            console.log('Workflow result', data);

                            this.currentData = data;

                            return data;
                        });

                    },
                }
            }).whenClosed((object) => {
                if (!object?.output) {
                    return;
                }
                _.each(object?.output ?? [], (item) => {
                    _.each(item?.data ?? [], (serviceGroup) => {
                        // We have changes
                        changed = true;
                        this.calculation.additionalServiceGroups.push(serviceGroup);

                    });
                })
            });

        if (changed) {
            this.changed();
        }
    }

    removeAdditionalServiceGroup(index) {
        this.calculation.additionalServiceGroups.splice(index, 1);
        this.changed();
    }

    changed() {
        this.setUnsavedState();
        this._updatedUsedOccupancies();
        this.getDetailsDebounced();
    }

    _updatedUsedOccupancies() {
        const usedOccupancyTypes = [];

        if (this.calculation.baseOccupancyType?.id) {
            usedOccupancyTypes.push(this.calculation.baseOccupancyType.id);
        }

        for (const {occupancyType = []} of this.calculation.additionalRooms ?? []) {
            for (const {id} of occupancyType) {
                usedOccupancyTypes.push(id);
            }
        }

        this.usedOccupancyTypes = usedOccupancyTypes.length ? { id: { $in: usedOccupancyTypes } } : null;
    }

    attached() {
        this.subscription = this.ea.subscribe(
            "sio_form_post_submit",
            (event) => {
                if (
                    event.config &&
                    "tourism-hotel-calculation/calculation" ===
                        event.config.modelId
                ) {
                    if (event.response && event.response.data.id) {
                        sessionStorage.setItem(
                            "tourism_hotel_calculation/calculation",
                            event.response.data.id
                        );
                    }

                    this.bind();
                }
            }
        );
    }

    detached() {
        if (this.subscription) {
            this.subscription.dispose();
            this.subscription = null;
        }
    }

    generate() {
        this.runGenerate = true;
        this.getDetails();
    }

    getDetails() {
        if (!this.runGenerate) {
            this.endpoint = "tourism-hotel-calculation-details";
        } else {
            this.endpoint = "tourism-hotel-calculation-generate";
        }

        this.detailsLoading = true;
        this.signaler.signal("tourism-hotel-calculation");

        const data = {
            active: this.calculation.active,
            extension: this.calculation.extension,
            flatrate: this.calculation.flatrate,
            arrivalIncluded: this.calculation.flatrate
                ? this.calculation.arrivalIncluded
                : false,
            marginTax: this.calculation.marginTax,
            baseOccupancyType: this.calculation.baseOccupancyType,
            baseFare: this.calculation.baseFare,
            marketingChannel: this.calculation.marketingChannel,
            overwriteCommission: this.calculation.overwriteCommission,
            arrivalWeekDays: this.calculation.arrivalWeekDays,
            pricePerNight: this.calculation.pricePerNight,
            useAsNormal: this.calculation.useAsNormal,
            rowGeneratorEnabled: this.calculation.rowGeneratorEnabled,
            deviatingArrivalWeekDays: _.map(
                this.calculation.deviatingArrivalWeekDays,
                (item) => {
                    return _.pick(item, [
                        "fromDate",
                        "toDate",
                        "arrivalWeekDays",
                    ]);
                }
            ),
            agencyCommissionPercentage: this.calculation
                .agencyCommissionPercentage,
            agencyCommissionAbsolute: this.calculation.agencyCommissionAbsolute,
            retailPriceCommission: this.calculation.retailPriceCommission,
            duration: this.calculation.duration,
            currency: this.calculation.currency,
            additionalRooms: _.map(this.calculation.additionalRooms, (item) => {
                let newObject = _.pick(item, ["occupancyType"]);

                newObject.rows = _.map(item.rows, (row) => {
                    return _.pick(row, [
                        "fromDate",
                        "toDate",
                        "purchasePrice",
                        "purchasePricePerNight",
                        "contingentPerDate",
                        "utilization",
                        "retailPriceValue",
                        "retailPriceValuePerNight",
                    ]);
                });

                return newObject;
            }),
            additionalFares: _.map(this.calculation.additionalFares, (item) => {
                let newObject = _.pick(item, ["fare"]);

                newObject.rows = _.map(item.rows, (row) => {
                    return _.pick(row, [
                        "fromDate",
                        "toDate",
                        "purchasePrice",
                        "purchasePricePerNight",
                        "contingentPerDate",
                        "utilization",
                        "retailPriceValue",
                        "retailPriceValuePerNight",
                    ]);
                });

                return newObject;
            }),
            childReductions: _.map(this.calculation.childReductions, (item) => {
                let newObject = _.pick(item, [
                    "occupancyType",
                    "ageFrom",
                    "ageTo",
                    "childFrom",
                    "childTo",
                    "purchasePriceReduction",
                    "purchasePriceReductionPercentage",
                    "retailPriceReduction",
                    "retailPriceReductionPercentage",
                ]);

                newObject.rows = _.map(item.rows, (row) => {
                    return _.pick(row, [
                        "fromDate",
                        "toDate",
                        "purchasePrice",
                        "purchasePricePerNight",
                        "contingentPerDate",
                        "utilization",
                        "retailPriceValue",
                        "retailPriceValuePerNight",
                    ]);
                });

                return newObject;
            }),
            rows: _.map(this.calculation.rows, (item) => {
                return _.pick(item, [
                    "fromDate",
                    "toDate",
                    "purchasePriceSeason",
                    "contingentPerDate",
                    "utilization",
                    "retailPriceSeason",
                ]);
            }),
            surcharges: _.map(this.calculation.surcharges, (item) => {
                return _.pick(item, [
                    "fromDate",
                    "toDate",
                    "weekDays",
                    "arrivalType",
                    "occupancyType",
                    "creditor",
                    "purchasePrice",
                    "retailPriceValue",
                ]);
            }),
            hotelPurchasePrices: _.map(
                this.calculation.hotelPurchasePrices,
                (item) => {
                    return _.pick(item, ["price", "pricePerNight"]);
                }
            ),
            retailPrices: _.map(this.calculation.retailPrices, (item) => {
                return _.pick(item, ["retailPrice", "retailPricePerNight"]);
            }),
            rowGenerator: _.concat(
                _.map(this.calculation.rowGeneratorPurchasePrice, (item) => {
                    return _.extend(
                        _.pick(item, ["fromDate", "toDate", "priceValue"]),
                        { priceType: "purchasePrice" }
                    );
                }),
                _.map(this.calculation.rowGeneratorRetailPrice, (item) => {
                    return _.extend(
                        _.pick(item, ["fromDate", "toDate", "priceValue"]),
                        { priceType: "retailPrice" }
                    );
                })
            ),
        };
        this.client
            .post(this.endpoint, data)
            .then(
                (response) => {
                    this.calculationResult = response.data;
                    if (this.runGenerate) {
                        this.calculation.rows.splice(
                            0,
                            this.calculation.rows.length
                        );
                        for (const row of this.calculationResult.rows) {
                            this.calculation.rows.push(row);
                        }

                        this.runGenerate = false;
                    }
                },
                (error) => {
                    console.error(error);
                    this.flash.error(error.data.localizedMessage);
                    this.runGenerate = false;
                }
            )
            .finally(() => {
                this.signaler.signal("tourism-hotel-calculation");
                this.detailsLoading = false;
            });

        this.endpoint = null;
    }

    save() {
        if (this.saving || this.detailsLoading) {
            return false;
        }

        const data = {
            fromDate: this.calculation.fromDate,
            toDate: this.calculation.toDate,
            active: this.calculation.active,
            extension: this.calculation.extension,
            flatrate: this.calculation.flatrate,
            arrivalIncluded: this.calculation.flatrate
                ? this.calculation.arrivalIncluded
                : false,
            marginTax: this.calculation.marginTax,
            baseOccupancyType: this.calculation.baseOccupancyType,
            baseFare: this.calculation.baseFare,
            marketingChannel: this.calculation.marketingChannel,
            overwriteCommission: this.calculation.overwriteCommission,
            arrivalWeekDays: this.calculation.arrivalWeekDays,
            pricePerNight: this.calculation.pricePerNight,
            useAsNormal: this.calculation.useAsNormal,
            rowGeneratorEnabled: this.calculation.rowGeneratorEnabled,
            deviatingArrivalWeekDays: _.map(
                this.calculation.deviatingArrivalWeekDays,
                (item) => {
                    return _.pick(item, [
                        "fromDate",
                        "toDate",
                        "arrivalWeekDays",
                    ]);
                }
            ),
            agencyCommissionPercentage: this.calculation
                .agencyCommissionPercentage,
            agencyCommissionAbsolute: this.calculation.agencyCommissionAbsolute,
            retailPriceCommission: this.calculation.retailPriceCommission,
            duration: this.calculation.duration,
            currency: this.calculation.currency,
            additionalRooms: _.map(this.calculation.additionalRooms, (item) => {
                let newObject = _.pick(item, ["occupancyType"]);

                newObject.rows = _.filter(
                    _.map(item.rows, (row) => {
                        return _.pick(row, [
                            "fromDate",
                            "toDate",
                            "purchasePrice",
                            "purchasePricePerNight",
                            "contingentPerDate",
                            "utilization",
                            "retailPriceValue",
                            "retailPriceValuePerNight",
                        ]);
                    }),
                    (item) => {
                        return item.fromDate && item.toDate;
                    }
                );

                return newObject;
            }),
            additionalFares: _.map(this.calculation.additionalFares, (item) => {
                let newObject = _.pick(item, ["fare"]);

                newObject.rows = _.filter(
                    _.map(item.rows, (row) => {
                        return _.pick(row, [
                            "fromDate",
                            "toDate",
                            "purchasePrice",
                            "purchasePricePerNight",
                            "contingentPerDate",
                            "utilization",
                            "retailPriceValue",
                            "retailPriceValuePerNight",
                        ]);
                    }),
                    (item) => {
                        return item.fromDate && item.toDate;
                    }
                );

                return newObject;
            }),
            childReductions: _.map(this.calculation.childReductions, (item) => {
                let newObject = _.pick(item, [
                    "occupancyType",
                    "ageFrom",
                    "ageTo",
                    "childFrom",
                    "childTo",
                    "purchasePriceReduction",
                    "purchasePriceReductionPercentage",
                    "retailPriceReduction",
                    "retailPriceReductionPercentage",
                ]);

                newObject.rows = _.filter(
                    _.map(item.rows, (row) => {
                        return _.pick(row, [
                            "fromDate",
                            "toDate",
                            "purchasePrice",
                            "purchasePricePerNight",
                            "contingentPerDate",
                            "utilization",
                            "retailPriceValue",
                            "retailPriceValuePerNight",
                        ]);
                    }),
                    (item) => {
                        return item.fromDate && item.toDate;
                    }
                );

                return newObject;
            }),
            rows: _.filter(
                _.map(this.calculation.rows, (item) => {
                    return _.pick(item, [
                        "fromDate",
                        "toDate",
                        "purchasePriceSeason",
                        "contingentPerDate",
                        "utilization",
                        "retailPriceSeason",
                    ]);
                }),
                (item) => {
                    return item.fromDate && item.toDate;
                }
            ),
            rowGenerator: _.filter(
                _.concat(
                    _.map(
                        this.calculation.rowGeneratorPurchasePrice,
                        (item) => {
                            return _.extend(
                                _.pick(item, [
                                    "fromDate",
                                    "toDate",
                                    "priceValue",
                                ]),
                                { priceType: "purchasePrice" }
                            );
                        }
                    ),
                    _.map(this.calculation.rowGeneratorRetailPrice, (item) => {
                        return _.extend(
                            _.pick(item, ["fromDate", "toDate", "priceValue"]),
                            { priceType: "retailPrice" }
                        );
                    })
                ),
                (item) => {
                    return item.fromDate && item.toDate;
                }
            ),
            surcharges: _.filter(
                _.map(this.calculation.surcharges, (item) => {
                    return _.pick(item, [
                        "fromDate",
                        "toDate",
                        "weekDays",
                        "occupancyType",
                        "creditor",
                        "arrivalType",
                        "purchasePrice",
                        "retailPriceValue",
                    ]);
                }),
                (item) => {
                    return (
                        (item.fromDate && item.toDate) ||
                        item.weekDays.length > 0
                    );
                }
            ),
            additionalServiceGroups: _.map(
                this.calculation.flatrate ? this.calculation.additionalServiceGroups : [], (item) => {
                    return _.pick(item, [
                        "fromDate",
                        "toDate",
                        "service",
                        "data",
                        "day",
                        "duration",
                        "category",
                        "locales",
                        "required",
                        "priceNotIncluded",
                        "byParticipant",
                        "additionalData"
                    ]);
                }),

            //We can not filter here because of index mapping
            hotelPurchasePrices: _.map(
                this.calculation.hotelPurchasePrices,
                (item) => {
                    return _.pick(item, ["price", "pricePerNight"]);
                }
            ),
            retailPrices: _.map(this.calculation.retailPrices, (item) => {
                return _.pick(item, ["retailPrice", "retailPricePerNight"]);
            }),
        };
        console.debug("data", data);

        this.saving = true;

        this.client
            .put(
                "tourism-hotel-calculation/calculation-edit/" +
                    this.calculation.id,
                data
            )
            .then(
                () => {
                    this.ea.publish("sio_form_post_submit", {
                        config: { modelId: this.calculation.modelId },
                    });
                    this.flash.success("Erfolgreich gespeichert");
                },
                (response) => {
                    if (response.data && response.data.localizedMessage) {
                        this.flash.error(response.data.localizedMessage);
                    } else if (response.status === 403) {
                        this.flash.error("sio.access_denied");
                    } else {
                        this.flash.error("Fehler beim Speichern");
                    }
                }
            )
            .finally(() => {
                this.setSavedState();
                this.saving = false;
            });

        return false;
    }

    createOrUpdate() {
        return this.workflowService
            .trigger('tourism-hotel-calculation/create-or-update-calculation', [this.itinerary.id], null)
            .then(
                () => {
                    this.ea.publish("sio_form_post_submit", {
                        config: {modelId: this.calculation.modelId},
                    });
                }
            )
    }

    setSavedState() {
        // Declares that calculation is currently saved. Allows user to switch between tabs without warning.

        const changesKey = this._getControlUID(this.calculation);
        this.ea.publish("sio_unregister_unsaved_changes", { changesKey });
        this.unsaved = false;
    }

    setUnsavedState() {
        // Declares that calculation is currently unsaved. Prevents user from accidental switch between tabs and losing changes.

        const changesKey = this._getControlUID(this.calculation);
        this.ea.publish("sio_register_unsaved_changes", { changesKey });
        this.unsaved = true;
    }

    async getHotelCalculationConfiguration() {
        let response = await this.client.get(
            "tourism-hotel-calculation/configuration/" + this.itinerary.id
        );

        console.log("Configuration", response);

        this.configuration = response;
    }

    getRoomMatrixEnabled() {
        return this.configuration?.roomMatrixEnabled ?? false;
    }

    getOccupancyTypeConfig() {
        let config = {
            modelId: "tourism-room/occupancy-type",
            hideCreateAction: true,
            required: true,
        };

        let occupancyTypes = this.configuration?.occupancyTypes ?? {};

        console.log("TYPES", Object.entries(occupancyTypes));

        config.conditions = {
            id: { $in: Object.entries(occupancyTypes).map((type) => type[0]) },
        };

        return config;
    }

    getOccupancyTypeConfigMultiple() {
        let config = {
            modelId: "tourism-room/occupancy-type",
            hideCreateAction: true,
            required: true,
            multiple: true,
        };

        let occupancyTypes = this.configuration?.occupancyTypes ?? {};

        console.log("TYPES", Object.entries(occupancyTypes));

        config.conditions = {
            id: { $in: Object.entries(occupancyTypes).map((type) => type[0]) },
        };

        return config;
    }
}
