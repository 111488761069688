import {createSelector} from "@reduxjs/toolkit";
import {selectAllChoices} from "./choices-slice";
import {selectAllDates} from "./dates-slice";
import {selectAllPrices} from "./dates-prices-slice";
import {selectAllEntries} from "./entries-slice";
import {getAllFields} from "./fields-slice";

const selectEntriesSave = createSelector(
    state => selectAllEntries(state),
    entries => entries.map(
        ({id, identifier, service, occupancyChoices, childChoices, count, day, duration, exchangeRate, additionalService, group, data}) =>
            ({id, identifier, service, occupancyChoices, childChoices, count, day, duration, exchangeRate, additionalService, group, data})
    )
)
const selectFieldSave = createSelector(
    getAllFields,
    ({
         id,
         dateRanges,
         daysCount,
         participantsRange,
         marginParticipants,
         dynamic
     }) => ({
        id,
        dateRanges,
        daysCount,
        participantsRange,
        marginParticipants,
        dynamic
    })
)
const selectChoiceSave = createSelector(
    state => selectAllChoices(state),
    choices => choices.map(
        ({id, override, accommodation, occupancyType, fare, noExternalBooking, previousPrice}) =>
            ({id, override, accommodation, occupancyType, fare, noExternalBooking, previousPrice})
    )
)
const selectDatesSave = createSelector(
    state => selectAllDates(state),
    state => selectAllPrices(state),
    (dates, prices) => dates.map(({id: dateId, date}) => ({
        date,
        prices: Object.fromEntries(prices
            .filter(({id}) => id.split('|')[0] === dateId)
            .map(({id, price}) => [id.split('|')[1], price])
        )
    }))
)
export const selectCalculationSaveParams = createSelector(
    selectFieldSave, selectEntriesSave, selectChoiceSave, selectDatesSave,
    (fields, entries, occupancyChoices, dates) => ({...fields, entries, occupancyChoices, dates})
)
